<template>
  <div class="map">
    <div id="map" style="width: 100%; height: 100vh"></div>
  </div>
</template>

<script>
import Vue from 'vue';

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = './js/script-mapa.js';
document.body.appendChild(script);

export default Vue.extend({
  name: 'Map',
  data: () => ({})
});
</script>

<style>
body {
  cursor: none;
}

.mapboxgl-canvas-container {
  cursor: url(data:application/cur;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5AkFAyYcrlYARQAAA2hJREFUSMfFll9oHFUYxX/fnc1m3aTVWFpQi1EfFasgRYpNyNJUyVosxUYaV1tTKRQUC4pIior4j+JbKVWkYLFkpYjYl7hU3XQLUnxoCwpVrOZPSd1o02bTbJLd2flzrw+Z1XFxk0ZRz8vM/c6ZOd/MPffOQIBkLk0YyVz6T7Vavra2GF+LSFWQSaRI5tK3A5uAbzKJ1Fcbsoep4VcAjwFjmUTqs4rnCGBCfBzYBtjN0fjHk6WrXj1jCXW3GrgIaEBVfGfHYGfvkRC/HBgBWgDl+u4rX3Y+9WaIbwQ+B9YDlqf9D77YsP3pesYqdP5stSYizDn2czXaLVVTJcKsY79aw98J3A1YIkLZrexkAfxuXPGcrG80llKUnDKFUvFUWGh7zllf+8oShe05XC3PDIb5slvJe9qfVSL42mfanj23qHHb8UMMbuzNjhTyjw9d+fnbselL+37c+tKeqqjt+CFObOw999PkxcRwIX/6wtQvh13tbQrzuQd3Tpy/MpYcLYznRgr5gVmn/FA902QuPT/H1XDUEwFL5o0xPP/91woJYmTA8V05uKbdB5BQIu9SIgcc37tDiXIiyvpwdGr87e+2vGAAunLp2yxR+13trRHQERX5dKCj58U/GuhvVmLt97Tf4Wtf6iRZacxE9pO37heAh09+dI/W+szY9KVIybUR4JblK7k+tuw1bfTrgtxsMKfyxYnWol0CYFVzCyubWvoHOnqeTObSAhy7PDe1ebI0HV4sfz2/Imsj8+9bdkzZM5Gya88qkQPxhtgDzY3xdm10D7BPhPUzlXLrnGNjKfVu1GpYdUNs2VZt9LpkLn0TEHN8d3PRnkOJeh8YBqw6y/fyUHffmUiwiazwjUaQkeHuvXu7cv27BGkHmoILbtRGA1SGuvue6TrR3ykijwY3t4BWbQwAQ919u7kGqJqNxMwPxNTRz/MieoH9gKUY/+f4X409ETmLMb8Cg4vopW5Ql2qcSaSIKOs9g7nPN7qvhteAC/h1GqjyzsJ91fksHmvrdoDxUP1CcLwV2G0MTwTjqqYYuKwGdgFr59NonH80x5lEKgv8EAwPgllnjEFEXg7400FzUeAN4BEwiEj6bxuH/ho6gKPAnIHRpuh12+INsaMh6b3AkeDpJ6NWwzuxSOOefzWRC/3SXCt+AxhIgpb1tz17AAAAAElFTkSuQmCC),
    auto !important;
}
</style>
